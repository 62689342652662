<template>
  <div
    v-if="field.field_type === 'char'"
    v-frag
  >
    <label for="field.name">{{ field.label }}</label>
    <input
      :id="field.name"
      :value="field.value"
      type="text"
      :name="field.name"
      @blur="updateStore_extra_form"
    >
  </div>

  <div
    v-else-if="field.field_type === 'list'"
    v-frag
  >
    <label for="field.name">{{ field.label }}</label>
    <Dropdown
      :options="field.options"
      :selected="selected_extra_form_list"
      :selection.sync="selected_extra_form_list"
    />
  </div>
  <div
    v-else-if="field.field_type === 'integer'"
    v-frag
  >
    <label for="field.name">{{ field.label }}</label>
    <div class="ui input">
      <!-- //* si click sur fléche dans champ input, pas de focus, donc pas de blur, donc utilisation de @change -->
      <input
        :id="field.name"
        :value="field.value"
        type="number"
        :name="field.name"
        @change="updateStore_extra_form"
      >
    </div>
  </div>
  <div
    v-else-if="field.field_type === 'boolean'"
    v-frag
  >
    <div class="ui checkbox">
      <input
        :id="field.name"
        class="hidden"
        type="checkbox"
        :checked="field.value"
        :name="field.name"
        @change="updateStore_extra_form"
      >
      <label for="field.name">{{ field.label }}</label>
    </div>
  </div>
  <div
    v-else-if="field.field_type === 'date'"
    v-frag
  >
    <label for="field.name">{{ field.label }}</label>
    <input
      :id="field.name"
      :value="field.value"
      type="date"
      :name="field.name"
      @blur="updateStore_extra_form"
    >
  </div>
  <div
    v-else-if="field.field_type === 'decimal'"
    v-frag
  >
    <label for="field.name">{{ field.label }}</label>
    <div class="ui input">
      <input
        :id="field.name"
        :value="field.value"
        type="number"
        step=".01"
        :name="field.name"
        @change="updateStore_extra_form"
      >
    </div>
  </div>
  <div
    v-else-if="field.field_type === 'text'"
    v-frag
  >
    <label :for="field.name">{{ field.label }}</label>
    <textarea
      :value="field.value"
      :name="field.name"
      rows="3"
      @blur="updateStore_extra_form"
    />
  </div>
</template>

<script>
import frag from 'vue-frag';
import Dropdown from '@/components/Dropdown.vue';

export default {
  name: 'FeatureExtraForm',

  directives: {
    frag,
  },

  components: {
    Dropdown,
  },

  props: {
    field: {
      type: Object,
      default: null,
    }
  },

  computed: {
    selected_extra_form_list: {
      get() {
        return this.field.value || '';
      },
      set(newValue) {
        //* set the value selected in the dropdown
        let newExtraForm = this.field;
        newExtraForm['value'] = newValue;
        this.$store.commit('feature/UPDATE_EXTRA_FORM', newExtraForm);
      },
    },
  },

  methods: {
    updateStore_extra_form(evt) {
      let newExtraForm = this.field;
      if (this.field.field_type === 'boolean') {
        newExtraForm['value'] = evt.target.checked; //* if checkbox use "checked"
      } else {
        newExtraForm['value'] = evt.target.value;
      }
      this.$store.commit('feature/UPDATE_EXTRA_FORM', newExtraForm);
    },
  },
};
</script>