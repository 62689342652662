<template>
  <div>
    <Multiselect
      v-model="selection"
      :options="results"
      :options-limit="10"
      :allow-empty="true"
      track-by="feature_id"
      label="title"
      :reset-after="false"
      select-label=""
      selected-label=""
      deselect-label=""
      :searchable="true"
      :placeholder="'Rechercher un signalement ...'"
      :show-no-results="true"
      :loading="loading"
      :clear-on-select="false"
      :preserve-search="true"
      @search-change="search"
      @select="select"
      @close="close"
    >
      <template slot="clear">
        <div
          v-if="selection"
          class="multiselect__clear"
          @click.prevent.stop="selection = null"
        >
          <i class="close icon" />
        </div>
      </template>
      <span slot="noResult">
        Aucun résultat.
      </span>
      <span slot="noOptions">
        Saisissez les premiers caractères ...
      </span>
    </Multiselect>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import Multiselect from 'vue-multiselect';

export default {
  name: 'SearchFeature',

  components: {
    Multiselect
  },

  data() {
    return {
      loading: false,
      selection: null,
      text: null,
      results: []
    };
  },

  computed: {
    ...mapState('feature', [
      'features'
    ])
  },

  watch: {
    text: function(newValue) {
      this.loading = true;
      this.GET_PROJECT_FEATURES({
        project_slug: this.$route.params.slug,
        feature_type__slug: this.$route.params.slug_type_signal,
        search: newValue,
        limit: '10'
      })
        .then(() => {
          if (newValue) {
            this.results = this.features;
          } else {
            this.results.splice(0);
          }
          this.loading = false;
        });
    }
  },

  created() {
    this.RESET_CANCELLABLE_SEARCH_REQUEST();
  },

  methods: {
    ...mapMutations(['RESET_CANCELLABLE_SEARCH_REQUEST']),
    ...mapActions('feature', [
      'GET_PROJECT_FEATURES'
    ]),
    search(text) {
      this.text = text;
    },
    select(e) {
      this.$emit('select', e);
    },
    close() {
      this.$emit('close', this.selection);
    }
  }
};
</script>

<style scoped>

</style>
